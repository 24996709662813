<template>
  <div>
    <Loader v-if="globalStore.isLoading" />
    <main class="main-content mt-0">
      <div class="min-vh-100 overflow-hidden d-flex justify-content-center align-items-center p-0" :style="{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }">
        <div class="row w-100 d-flex justify-content-center">
          <div class="col-lg-4 col-xl-4 col-xxl-3 col-md-7">
            <slot />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import Loader from "~/components/Layout/Loader.vue";

const { globalStore } = useNuxtApp();
const route = useRoute();
const { runtimeConfig } = useNuxtApp();
const background = runtimeConfig.public.loginBackground;

if (route.meta.title) useHead({ titleTemplate: titleChunk => `${titleChunk} - ${route.meta.title}` });
</script>
